var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[{
        big: _vm.big,
        correct: _vm.color === 'correct', 
        partial: _vm.color === 'partial', 
        incorrect: _vm.color === 'incorrect',
        'color-blind': _vm.colorBlindMode,
    }, _vm.isBig && _vm.keyboardName],attrs:{"id":"key"},on:{"click":_vm.handleClick}},[(_vm.isBig)?_c('div',[_c('img',{staticClass:"icon",attrs:{"src":`/icons/${_vm.keyContent.toLowerCase()}.svg`,"alt":_vm.keyContent}})]):[_vm._v(" "+_vm._s(_vm.keyContent)+" ")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }